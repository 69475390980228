<template>
  <div id="formulario-edit-info">   
    <ExibirErrosConcept :errorsValidacao="errorsValidacao" ref="errosComponent"></ExibirErrosConcept>
    <vx-card>
        <div class="vx-row mb-4">
            <div class="vx-col md:w-1/2 w-full">                    
                <vs-button class="ml-auto mt-2" size="small" @click="gravar" color="success">Gravar</vs-button>
                <vs-button class="ml-4 mt-2" size="small" @click="excluir" v-show="isDeletable" color="danger">Excluir</vs-button>            
                <vs-button class="ml-4 mt-2" size="small" type="border" color="dark" @click="voltar">Voltar</vs-button>             
            </div>
        </div>
        <div class="vx-row">
            <div class="vx-col w-1/2"><label class="vs-input--label">Nome</label></div>     
            <div class="vx-col w-1/2"><label class="vs-input--label">Telefone</label></div>             
        </div>    
        <div class="vx-row">
            <div class="vx-col w-1/2 flex">
                <vs-input class="w-full" v-model="data_local.nome" :maxlength="255"/>   
            </div>     
            <div class="vx-col w-1/2 flex">                
                <vs-textarea v-model="data_local.telefone" />
            </div>              
        </div> 

        <div class="vx-row mt-3">
            <div class="vx-col w-1/2"><label class="vs-input--label">CPF/CNPJ</label></div>                             
            <div class="vx-col w-1/2"><label class="vs-input--label">E-mail</label></div>                             
        </div>    
        <div class="vx-row">
            <div class="vx-col w-1/2 flex">
                <vs-input class="w-full" v-model="data_local.cpfCNPJ" :maxlength="20"/>   
            </div>     
            <div class="vx-col w-1/2 flex">
                <vs-input class="w-full" v-model="data_local.email" :maxlength="255"/>   
            </div>                               
        </div>             
        <div class="vx-row mt-3">
            <div class="vx-col w-full"><label class="vs-input--label">Endereço</label></div>                                                     
        </div>                    
        <div class="vx-row">
            <div class="vx-col w-full flex">
                <vs-input class="w-full" v-model="data_local.endereco"/>   
            </div>                                 
        </div>   
        <div class="vx-row mt-3">
            <div class="vx-col w-1/4"><label class="vs-input--label">Número</label></div>                                         
            <div class="vx-col w-3/4"><label class="vs-input--label">Complemento</label></div>                                         
        </div>                    
        <div class="vx-row">
            <div class="vx-col w-1/4 flex">
                <vs-input class="w-full" v-model="data_local.numeroEndereco"/>   
            </div>                     
            <div class="vx-col w-3/4 flex">
                <vs-input class="w-full" v-model="data_local.complemento"/>   
            </div>                                 
        </div>
        <div class="vx-row mt-3">
            <div class="vx-col w-1/4"><label class="vs-input--label">CEP</label></div>     
            <div class="vx-col w-1/4"><label class="vs-input--label">Bairro</label></div>                                         
            <div class="vx-col w-1/4"><label class="vs-input--label">Cidade</label></div>                                         
            <div class="vx-col w-1/4"><label class="vs-input--label">Estado</label></div>                                         
        </div>    
        <div class="vx-row">
            <div class="vx-col w-1/4 flex">
                <vs-input class="w-full" v-model="data_local.cep" :maxlength="10"/>   
            </div>     
            <div class="vx-col w-1/4 flex">
                <vs-input class="w-full" v-model="data_local.bairro"/>   
            </div>     
            <div class="vx-col w-1/4 flex">
                <vs-input class="w-full" v-model="data_local.cidade"/>   
            </div>     
            <div class="vx-col w-1/4 flex">
                <v-select                                               
                    :options="CONSTANTE_UFS" 
                    v-model="data_local.estado"    
                    :clearable="false"
                    :reduce="r => r.data"     
                    style="z-index: 10000 !important;"                                                                    
                    class="v-select w-full">
                </v-select>  
            </div>     
        </div>  
        <div class="vx-row">            
            <div class="vx-col w-1/2"><label class="vs-input--label">Observação</label></div>     
            <div class="vx-col w-1/2"><label class="vs-input--label">Dados para Pagamento</label></div>     
        </div>    
        <div class="vx-row">    
            <div class="vx-col w-1/2 flex">
                <vs-textarea v-model="data_local.observacao" />
            </div>  
            <div class="vx-col w-1/2 flex">
                <vs-textarea v-model="data_local.dadosParaPagamento" />
            </div>    
        </div>
                   
        <vs-button class="mt-4" size="small" color="primary" type="filled" icon-pack="feather"  @click="verOrdemServicos"> Ver Ordens de Serviço [{{this.data_local.qtdOSExecutadas}}]</vs-button>          
    </vx-card>  
  </div>
</template>

<script>
import axios from "@/axios.js"
import generico from "@/generico.js"
import { CONSTANTE_UFS } from '@/constantesComboBox.js'

const URL_SERVLET = "/TecnicoRest";
const NAME_ROUTE_SEARCH = "cadastro-tecnicos-pesquisar";

export default {     
  props: {
    registroOriginal: {
      type: Object,
      required: false,
      default: function () {
            return {nome: null, telefone: null, observacao: null, cpfCNPJ: null, 	endereco: null, 	numeroEndereco: null, 	
            bairro: null, 	cidade: null, 	estado: null, 	cep: null, 	email: null, 	complemento: null, 	dadosParaPagamento: null}
       }
    },
  },
  data() {
    return {        
        errorsValidacao: [],  
        data_local: JSON.parse(JSON.stringify(this.registroOriginal)),           
    }
  },  
  created(){
    this.CONSTANTE_UFS = CONSTANTE_UFS;
  }, 
  mounted() {
      
  },
  computed: {        
    isDeletable() {
       return this.data_local != null && this.data_local.hasOwnProperty("id");
    },    
  },
  methods: {  
    verOrdemServicos(){
         this.$router.push({
            name: "cadastro-ordem-servico-pesquisar",
            params: {
              idTecnico: this.data_local.id
            }
      })
    },
    excluir() {
        this.$vs.dialog({
          type: 'confirm', color: 'warning', title: 'Atenção', text: 'Deseja excluir ?', acceptText: 'Sim', cancelText: 'Não', accept: this.excluirRequest
        });
    },
    excluirRequest() {
        this.$vs.loading();  
        const params = new URLSearchParams();        
        params.append('outputFormat', 'JSON');
        params.append('method', 'Delete');      
        params.append('id', this.data_local.id);   
                
        axios.post(URL_SERVLET, params, {              
                credentials: 'include', responseEncoding: 'iso88591', withCredentials: true } )
        .then((response) => {
            if (response.data.hasOwnProperty("codigo") && response.data.codigo === "99" ) {
                this.$router.push({ path: '/' });
            } else {
                this.$vs.loading.close();
                if (response.data.hasOwnProperty("error")) {
                        this.$vs.dialog({ color: 'danger', title: 'Erro', text: response.data.error, acceptText: 'OK'})
                } else {
                    this.$router.push({ name: NAME_ROUTE_SEARCH })
                }                   
            }
        })
        .catch((error) => { 
            this.$vs.loading.close();
            /* eslint-disable no-console */ console.log("EFETUAR LOGOFF! - gravar - " + error); 
        })  
    },
    gravar() {        
        if (this.formularioValido()) { 
            this.$vs.loading();  
            const params = new URLSearchParams();        
            params.append('outputFormat', 'JSON');
            
            if (this.registroOriginal.hasOwnProperty("id")) {
                params.append('method', 'Update');      
                params.append('id', this.data_local.id);      
            }  else {
                params.append('method', 'Insert');      
            }  

            params.append('nome', this.data_local.nome);      
            
            if (this.data_local.telefone) {
                params.append('telefone', this.data_local.telefone);      
            }

            if (this.data_local.observacao) {
                params.append('observacao', this.data_local.observacao);      
            }
            if (this.data_local.cpfCNPJ) {
                params.append('cpfCNPJ', this.data_local.cpfCNPJ);            
            }
            if (this.data_local.endereco) {
                params.append('endereco', this.data_local.endereco);
            }
            if (this.data_local.numeroEndereco) {
                params.append('numeroEndereco', this.data_local.numeroEndereco);
            }
            if (this.data_local.bairro) {
                params.append('bairro', this.data_local.bairro);
            }
            if (this.data_local.cep) {
                params.append('cep', this.data_local.cep);
            }
            if (this.data_local.cidade) {
                params.append('cidade', this.data_local.cidade);
            }
            if (this.data_local.estado) {
                params.append('estado', this.data_local.estado);
            }            
            if (this.data_local.complemento) {
                params.append('complemento', this.data_local.complemento);            
            }
            if (this.data_local.email) {
                params.append('email', this.data_local.email);
            }

            if (this.data_local.dadosParaPagamento) {
                params.append('dadosParaPagamento', this.data_local.dadosParaPagamento);
            }
            
            
            axios.post(URL_SERVLET, params, {              
                    credentials: 'include', responseEncoding: 'iso88591', withCredentials: true } )
            .then((response) => {
                this.$vs.loading.close();
                if (response.data.hasOwnProperty("codigo") && response.data.codigo === "99" ) {
                    this.$router.push({ path: '/' });
                } else {
                    /* eslint-disable no-console */ console.log(response.data);
                    if (response.data.hasOwnProperty("error")) {
                            this.$vs.dialog({ color: 'danger', title: 'Erro', text: response.data.error, acceptText: 'OK'})
                    } else {
                        this.$router.push({ name: NAME_ROUTE_SEARCH })
                    }                   
                }
            })
            .catch((error) => { 
                this.$vs.loading.close();
                /* eslint-disable no-console */ console.log("EFETUAR LOGOFF! - gravar - " + error); 
            })  
        }
         
    },
    voltar() {
        this.$router.push({
            name: NAME_ROUTE_SEARCH                     
        })
    },
    formularioValido() {
        this.$refs.errosComponent.iniciar();
        this.errorsValidacao = [];
      
        if (!this.data_local.nome) {
            this.errorsValidacao.push("Nome obrigatório");
        }

        if (this.data_local.cpfCNPJ && !generico.isCPFValido(this.data_local.cpfCNPJ) && !generico.isCNPJValido(this.data_local.cpfCNPJ)) {
            this.errorsValidacao.push("CPF ou CNPJ inválido");
        }

        if (!this.errorsValidacao.length) {
            return true;
        }   
        return false;
    },
  }
}
</script>

<style scoped>
  .tabelaComScroll {      
      overflow: auto;  
      height: calc(100vh - 300px);
      position: relative;      
    } 
</style>